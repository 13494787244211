<!-- 用户审核  -->
<template>
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="手机号：">
          <el-input maxlength="11" placeholder="请输入手机号" v-model="queryForm.phone"
            @input="(e) => (queryForm.phone = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="公司名称：">
          <el-input placeholder="请输入公司名称" v-model="queryForm.company"
            @input="(e) => (queryForm.company = validSpecialChar(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="负责人：">
          <el-input maxlength="11" placeholder="请输入姓名" v-model="queryForm.principal"
            @input="(e) => (queryForm.principal = validSpecialChar(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="审核状态：">
          <el-select popper-class="my-select" clearable v-model="queryForm.status" placeholder="请选择审核状态"
            @change="queryForm.alarm_type = queryForm.status ? queryForm.status : 0; onSubmit()">
            <el-option v-for="item in getOptions('AIAccountStatus')" :key="item.value" :label="item.name"
              :value="item.value">
            </el-option>
            <el-option label="全部" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="用户审核">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="status">
              <span>{{ getWayName("AIAccountStatus", row.status) }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="setting" icon="bianji" hint="审核" @click="fnAuditHandle(row)" v-if="row.status == 1 && isShowBtn('5490175b5d464941826f7113')">
              </lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="fnAuditHandle(row)" v-else></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe >
          <el-table-column  prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="company" label="公司名称"></el-table-column>
          <el-table-column prop="principal" label="负责人"></el-table-column>
          <el-table-column  label="审核状态">
            <template slot-scope="scope">
              <span>{{ getWayName("AIAccountStatus", scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column  label="审核时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="setting" icon="shenhe" hint="审核" @click="fnAuditHandle(scope.row)" v-if="scope.row.status == 1 && isShowBtn('5490175b5d464941826f7113')">
              </lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="fnAuditHandle(scope.row)" v-else></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChange" @current-change="currentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 审核查看弹窗 -->
    <lebo-dialog append-to-body :title="counterStatus===1 ? '用户审核' : '查看用户'" :isShow="showUserDetial" width="25%"
      @close="showUserDetial = false" footerSlot closeOnClickModal>
      <checkUserDetial @closeDialog="closeUserDetial" v-if="showUserDetial" :id="dialogCounterCheckId"
        :status="counterStatus"></checkUserDetial>
    </lebo-dialog>
  </div>
</template>

<script>
import { getAIAccountCheckList } from '@/api/maintenanceCenter'
import { mapActions, mapState, mapGetters } from 'vuex'
import checkUserDetial from './components/checkUserDetial/index.vue'
export default {
  name: 'AIUserReview',
  components: { checkUserDetial },
  props: {},
  data () {
    return {
      // 查询表单
      queryForm: {
        principal: '',
        phone: '',
        company: '',
        status: 0,
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      showUserDetial: false, // 查看审核用户弹窗
      dialogCounterCheckId: '', // 用户审核id
      counterStatus: 1, // 用户审核状态
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '公司名称',
            prop: 'company'
          }, {
            label: '负责人',
            prop: 'principal'
          }, {
            label: '审核状态',
            prop: 'status',
            slot: true
          }, {
            label: '审核时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])

  },
  methods: {
    // 获取审核列表
    async fnGetAIAccountCheckList () {
      const res = await getAIAccountCheckList(this.queryForm)
      console.log('审核列表', res)
      this.tableData = res.Data.DataList
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAIAccountCheckList()
    },
    // 关闭弹窗
    closeUserDetial () {
      this.showUserDetial = false
      this.fnGetAIAccountCheckList()
    },
    // 每页条数改变
    sizeChange (val) {
      this.page.currentPage = 1
      this.page.pageSize = val
      this.queryForm.PageSize = val
      this.fnGetAIAccountCheckList()
    },
    // 页码改变
    currentChange (val) {
      this.queryForm.PageIndex = val
      this.page.currentPage = val
      this.fnGetAIAccountCheckList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 审核用户
    fnAuditHandle (row) {
      this.dialogCounterCheckId = row._id
      this.counterStatus = row.status
      this.showUserDetial = true
    },
    // 查看用户
    lookDetails (row) {
      this.dialogCounterCheckId = row._id
      this.counterStatus = row.status
      this.showUserDetial = true
    }
  },
  created () { },
  mounted () {
    this.fnGetAIAccountCheckList()
  }
}
</script>

<style scoped></style>
